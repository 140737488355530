<script lang="ts" setup>
import Link1 from '@/components/Button/Link1.vue'
import Title2 from '@/components/Title/Title2.vue'
import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import type { NewsList } from '@/utils/types'

const props = defineProps({
  data: {
    type: Array as PropType<NewsList[]>,
    default: []
  }
})

SwiperCore.use([Autoplay, Pagination])

const currentIndex: Ref<number> = ref(0)

const swiperSlideSelector = ref()
const onSwiper = (data: any) => {
  //存放 swiper
  swiperSlideSelector.value = data
}

const swiperTo = (index: number) => {
  swiperSlideSelector.value.slideTo(index)
}

const handelSwiperChange = (data: any) => {
  currentIndex.value = data.realIndex
}

// const handleSlidePrev = () => {
//   currentIndex.value = currentIndex.value - 1;
//   if (currentIndex.value < 0) currentIndex.value = lists.value.length - 1;
//   swiperTo(currentIndex.value);
// };
// const handleSlideNext = () => {
//   currentIndex.value = currentIndex.value + 1;
//   if (currentIndex.value >= lists.value.length) currentIndex.value = 0;
//   swiperTo(currentIndex.value);
// };

const handleMouseenter = (index: any) => {
  swiperTo(index)
}
</script>

<template>
  <section class="news">
    <div class="wrap1144">
      <Title2 class="news__title" title="NEWS" />
      <div class="news__left">
        <ul class="news__lists">
          <li v-for="(item, index) in data" :key="index" @mouseenter="handleMouseenter(index)">
            <nuxt-link :to="item.link">
              <span class="news__lists__no">0{{ index + 1 }}</span>
              <span class="news__lists__title">{{ item.title.replace('<br />', '') }}</span>
            </nuxt-link>
          </li>
        </ul>
        <Link1 link="/news" class="news__link" />
      </div>
      <div class="news__right">
        <!-- <div class="swiper-list__box">
          <figure class="swiper-list__pic imh__opacity">
            <NuxtImg src="./images/homenews.jpg"></NuxtImg>
          </figure>
        </div> -->
        <swiper :autoplay="{
          delay: 3000
        }" :slides-per-view="1" :space-between="0" :speed="300" :loop="true" :pagination="true" :breakpoints="{
  768: {
    allowTouchMove: false,
    autoplay: false
  }
}" class="swiper-list" @swiper="onSwiper" @slide-change="handelSwiperChange">
          <swiper-slide v-for="(item, index) in data" :key="index">
            <div class="swiper-list__box">
              <figure class="swiper-list__pic imh__opacity">
                <NuxtImg :src="item.image"></NuxtImg>
              </figure>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
:deep(.swiper-pagination) {
  @include min-media(768) {
    display: none;
  }
}

.news {
  margin-bottom: 150px;

  @include max-media(767) {
    margin-bottom: 70px;
  }

  .wrap1144 {
    display: flex;
    flex-wrap: wrap;

    @include max-media(767) {
      max-width: 315px;
    }
  }

  &__title {
    width: 54%;
    margin-bottom: 26px;

    @include max-media(1044) {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
      order: 1;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    width: 55%;
    overflow: hidden;
    padding-right: 50px;

    @include max-media(1044) {
      width: 100%;
      order: 3;
      text-align: center;
    }

    @include max-media(768) {
      padding-right: 0;
    }
  }

  &__link {
    // margin-top: auto;
    width: 100px;
  }

  &__right {
    position: relative;
    width: 45%;
    top: -50px;
    padding-left: 15px;

    @include max-media(1044) {
      width: 100%;
      margin-top: 0;
      padding-top: 0;
      padding-left: 0;
      top: 0;
      order: 2;
    }
  }

  &__lists {
    margin-top: -15px;
    margin-bottom: 98px;

    @include max-media(1512) {
      margin-bottom: 46px;
    }

    @include max-media(767) {
      margin-top: 0;
      margin-bottom: 30px;
    }

    li {
      border-bottom: 1px solid #000;
      text-align: left;
    }

    a {
      display: flex;
      align-items: center;
      padding-top: 17px;
      padding-bottom: 17px;
    }

    &__no {
      width: 55px;
      text-align: center;
      font-size: 21px;
      color: #000;
      line-height: 1.5;

      @include max-media(767) {
        font-size: 12px;
        width: 20px;
      }
    }

    &__title {
      flex: 1;
      padding-left: 12px;
      font-size: 16px;
      color: #000;
      @include dot-one-line;

      @include max-media(767) {
        font-size: 10px;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.swiper-list {
  &__pic {
    img {
      display: block;
      width: 100%;
    }
  }
}

:deep(.swiper-pagination) {
  @include min-media(1180) {
    display: none;
  }
}

:deep(.swiper-pagination-bullet) {
  background: #d9d9d9;
  opacity: 1;
}

:deep(.swiper-pagination-bullet-active) {
  background: #000;
  margin: 0 11px !important;
}
</style>
